import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as moment from 'moment';
import $ from 'jquery';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import SuccessMoal from '../../modal/success-model';
import { MediaManagementAPI } from '../../../service/api';
import ErrorMoal from '../../modal/error-model';
import DatePicker from 'react-datepicker';
import { BRAND_ID_VALUE, thirty_Mnts_Interval } from '../../../utils/appConstants';
import "react-datepicker/dist/react-datepicker.css";
import http from '../../../service/httpService';
import { BASECOUNTRY_ID } from '../../../utils/appConstants';
import WoopraEvents from '../../../woopraConfig/WoopraEvents';
import Constants from '../../../woopraConfig/Woopraconstant';
import { UnitBuilder } from '../../../utils/unitResponseBuilder';
import { FormattedMessage, injectIntl } from 'react-intl';
import { TimePeriodFormatter } from '../../languageDropdown/timePeriodFormatter';
import { datePickerLocale } from '../../../locale/constant';

var todaysDate = new Date();
$(function () {
	$('#selectTime').keypress(function (e) {
		var keyCode = e.which || e.keyCode;
		if (!(keyCode === 97 || keyCode === 109 || keyCode === 112 || keyCode === 48 || keyCode === 49
			|| keyCode === 50 || keyCode === 51 || keyCode === 52 || keyCode === 53 || keyCode === 54
			|| keyCode === 55 || keyCode === 56 || keyCode === 57 || keyCode === 58 || keyCode === 32)) {
			return false;
		}
	});

	$('#ScheduleDate').on('focus', function (e) {
		e.preventDefault();
		$(this).blur();
	});
});

class MediaTransferUnits extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loaderIconVisible: false,
			Uploadedfile: {},
			files: {},
			error_modal: {
				open: false,
				message: ""
			},
			success_modal: {
				open: false,
				message: "",
				isHtml: false
			},
			UpdateNow: true,
			ScheduleUpdate: false,
			brandId: 0,
			startDate: "",
			Scheduledtime: "",
			maxdate: todaysDate,
			errormsg: "Drag menu file here.",
			userCheckedData: [],
			locationInfo: {},
			excludedProductList: [],
			autoInstall: true,
			autoInstallForSchedule: true,
			sheduleUpdateDetails: false,
			typeOfTransfer: "",
			scheduleDateTimeUtc: "",
			mediaSelectedItemsInfo: [],
			enteredUnits: '',
			isInputValid: false,
			totalUnitsCount: 0
		};
		this.UploadmenuFile = this.UploadmenuFile.bind(this);
		this.closeModel = this.closeModel.bind(this);
		this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.ResetDatepicker = this.ResetDatepicker.bind(this);
		this.handleUnitsChange = this.handleUnitsChange.bind(this);
	}

	componentWillMount() {
		if (this.props.location && this.props.location.state) {
			this.setState({
				userCheckedData: this.props.location.state.userCheckedData,
				excludedProductList: this.props.location.state.smartGroupResponse,
			});
			localStorage.setItem('totalUnitsCount', this.props.location.state.userCheckedData.length);
		}

		let mediaSelectedItemsInfo = this.props.mediaSelectedItemsInfo && this.props.mediaSelectedItemsInfo.SOFTWARE_ID ? this.props.mediaSelectedItemsInfo : JSON.parse(localStorage.getItem('selectedItemsInfo'));
		localStorage.setItem('selectedItemsInfo', JSON.stringify(mediaSelectedItemsInfo))

		let locationInfo = this.props.location && this.props.location.FILE_NAME ? this.props.location : JSON.parse(localStorage.getItem('locationInfo'));
		localStorage.setItem('locationInfo', JSON.stringify(locationInfo))

		this.setState({
			mediaSelectedItemsInfo: mediaSelectedItemsInfo,
			locationInfo: locationInfo,
			typeOfTransfer: this.props.location.transferType,
		});
	}

	componentDidMount() {
		WoopraEvents(`${Constants.UNITS_FOR_FILE_TRANSFER}`);
		document.body.style.backgroundColor = "#F2F2F2";
		$('div').removeClass("modal-backdrop fade show");
		$('.menuBrowse').click(function () { $('#menuUpdate').trigger('click'); });
		let Max_date = new Date(todaysDate);
		Max_date.setMonth(Max_date.getMonth() + 6)
		this.setState({
			maxdate: Max_date
		});

		$(".dropdownTimeOptions").hide();
		$("#selectTime").focus(function () {
			$(".dropdownTimeOptions").show();
		});
		var timeValue;
		$(document).click(function (e) {
			$(".dropdownTimeOptions").hide();
		});
		$('#timeSchedulePanel').click(function (e) {
			e.stopPropagation();
		});
		$(".dropdownTimeOptions > li > a").click(function (e) {
			e.preventDefault();
			timeValue = $(this).text();
			$("#selectTime").val(timeValue);
			$(".dropdownTimeOptions").hide();
		});
		$(".dropdownTimeOptions > li").click((e) => {
			e.preventDefault();
			$("#btnnext").addClass("btn-default-text activeState");
			$("#btnnext").removeClass("disabled");
		});
		$('#assignedSGOkModal').on('hidden.bs.modal', this.clearEnteredUnits);
		let totalUnitsCount = parseInt(localStorage.getItem('totalUnitsCount')) || 0;
		this.setState({ totalUnitsCount });

		
		let brandId = this.getBrandId();
		this.setState({ brandId });
	}

	componentWillUnmount() {
		$('#assignedSGOkModal').off('hidden.bs.modal', this.clearEnteredUnits);
		localStorage.removeItem('totalUnitsCount');
	}


	settimeselectonmenu = (e) => {
		if ($("#selectedfile").val() !== "" && $("#ScheduleDate").val() !== "" && $("#selectTime").val() !== "") {
			$("#btnnext").addClass("btn-default-text activeState  ");
			$("#btnnext").removeClass("disabled");
		}
	}

	setscheduleUpdate = (event) => {
		if (event.target.value === "ScheduleUpdate") {
			this.setState({
				ScheduleUpdate: true,
				UpdateNow: false
			});
			$("#Schedulediv").addClass('displayblock').removeClass('displaynone');
			$("#Schedulediv1").removeClass('displayblock').addClass('displaynone');
			if ($("#selectedfile").val() !== "" && $("#ScheduleDate").val() !== "" && $("#selectTime").val() !== "") {
				$("#btnnext").addClass("btn-default-text activeState");
				$("#btnnext").removeClass("disabled");
			}
			else {
				$("#btnnext").removeClass("activeState");
				$("#btnnext").addClass("disabled");
			}
		}
		else if (event.target.value === "UpdateNow") {

			this.setState({
				ScheduleUpdate: false,
				UpdateNow: true
			});

			$("#Schedulediv").addClass('displaynone').removeClass('displayblock');
			$("#Schedulediv1").removeClass('displaynone').addClass('displayblock');

			if (event.target.value = "UpdateNow") {
				$("#btnnext").addClass("btn-default-text activeState");
				$("#btnnext").removeClass("disabled");
			}
			else {
				$("#btnnext").removeClass("activeState");
				$("#btnnext").addClass("disabled");
			}

		}
	}

	handleChange = (date) => {
		this.setState({
			startDate: date
		});
		if (this.state.files.length > 0) {
			if (this.state.ScheduleUpdate && $("#selectTime").val() !== "") {
				$("#btnnext").addClass("btn-default-text activeState");
				$("#btnnext").removeClass("disabled");
			}
		}
	}

	onChangeSchedule = (e) => {
		let idx = e.target.selectedIndex;
		let Scheduledtime = e.target.options[idx].text;
		this.setState({ Scheduledtime: Scheduledtime });
	}

	closeModel() {
		this.setState({ error_modal: { open: false }, isInputValid: false })
		if (this.state.error_modal.message === "Request failed.") {
			this.props.history.push(`/mediaStatus`);
		}
	}

	onSuccessCloseModal = () => {
		this.setState({
			success_modal: {
				open: false
			}
		});
		if (this.state.typeOfTransfer === "smart-group-transfer") {
			this.props.history.push({
				pathname: `/mediaSmartGroupStatus`
			});
		} else {
			this.props.history.push({
				pathname: `/mediaStatus`,
				state: { showToast: true, totalUnitsCount: this.state.userCheckedData.length }
			});
		}
	}

	getBrandId = () => {
		let brandName = localStorage.getItem("brandName");
		return BRAND_ID_VALUE[brandName] || 0;
	}

	handleDrop = (files) => {

		let fileName = files[0].name;
		let fileextSplit = fileName.split('.');
		let fileext = fileextSplit[1];
		fileext = fileext.toLowerCase();
		let brandId = 0;
		let fileList = [];
		let message = "";
		fileList.push(files[0])
		brandId = this.getBrandId();
		if (brandId === 2) {
			if (fileext.toLowerCase() === "vhc" && files[0].type === "") {
				this.setState({
					files: fileList,
					brandId: brandId,
					errormsg: ""
				});
			}
			else {
				message = "KC1162";
			}
		}
		else if (brandId === 1 || brandId === 3 || brandId === 5 || brandId === 7 || brandId === 8 || brandId === 9 || brandId === 10) {
			if (fileext.toLowerCase() === "cbr") {
				this.setState({
					files: fileList,
					brandId: brandId,
					errormsg: ""
				});
			}
			else {
				message = "KC0668";
			}
		}
		else if (brandId === 6) {
			if (fileext.toLowerCase() === "sdf" || fileext.toLowerCase() === "db") {
				this.setState({
					files: fileList,
					brandId: brandId,
					errormsg: ""
				});
			}
			else {
				message = "KC1135";
			}
		}

		if (message !== "") {
			this.setState({
				loaderIconVisible: false,
				error_modal: {
					open: true,
					message: message
				}
			});
		}
		else {
			if (fileList.length > 0) {
				if (this.state.UpdateNow) {
					$("#btnnext").addClass("btn-default-text activeState");
					$("#btnnext").removeClass("disabled");
				}
				else if (this.state.ScheduleUpdate && ($("#ScheduleDate").val() !== "" && $("#selectTime").val() !== "")) {
					$("#btnnext").addClass("btn-default-text activeState");
					$("#btnnext").removeClass("disabled");
				}
			}
		}
	}

	ResetDatepicker = (e) => {
		e.preventDefault();
		return false;
	}

	UploadmenuFile = (e) => {
		//let _this = this;
		let brandId;
		if (e.target.files[0] !== null) {

			this.setState({
				loaderIconVisible: true
			});

			let fileName = e.target.files[0].name;
			fileName = fileName.replace(/[^a-zA-Z 0-9\n\r.]+/g, '_');
			let fileextSplit = fileName.split('.');
			let fileext = fileextSplit[1];
			fileext = fileext.toLowerCase();
			let fileList = [];
			fileList.push(e.target.files[0])

			let message = "";
			brandId = this.getBrandId();
			// Merco file type validation
			if (brandId === 2) {
				if (fileext.toLowerCase() === "vhc" && e.target.files[0].type === "") {
					this.setState({
						files: fileList,
						errormsg: ""
					});
				}
				else {
					message = "KC1162";
				}
			}
			// Merrychef & Frymaster file type validation
			else if (brandId === 1 || brandId === 3 || brandId === 5 || brandId === 7 || brandId === 8 || brandId === 9 || brandId === 10) {
				if (fileext.toLowerCase() === "cbr") {
					this.setState({
						files: fileList,
						errormsg: ""
					});
				}
				else {
					message = "KC0668";
				}
			}
			// Convotherm file type validation
			else if (brandId === 6) {
				if (fileext.toLowerCase() === "sdf" || fileext.toLowerCase() === "db") {
					this.setState({
						files: fileList,
						errormsg: ""
					});
				}
				else {
					message = "KC1135";
				}
			}

			if (message !== "") {
				this.setState({
					loaderIconVisible: false,
					error_modal: {
						open: true,
						message: message
					}
				});
			}
			else if (fileList.length > 0) {
				if (this.state.UpdateNow) {
					$("#btnnext").addClass("btn-default-text activeState");
					$("#btnnext").removeClass("disabled");
				}
				else if (this.state.ScheduleUpdate && ($("#ScheduleDate").val() !== "" && $("#selectTime").val() !== "")) {
					$("#btnnext").addClass("btn-default-text activeState");
					$("#btnnext").removeClass("disabled");
				}
			}
		}
		this.setState({
			loaderIconVisible: false,
			brandId: brandId
		});

		$("#menuUpdate").val('');
	}

	MercoMenuAssign = async (e) => {
		const { formatMessage } = this.props.intl;
		let brandId = this.getBrandId();
		this.setState({ loaderIconVisible: true, enteredUnits: '' });
		let menuScheduleDate = $("#ScheduleDate").val();
		//Checks for Valid Time.
		let selectTime = $("#selectTime").val().replace("APRÈS-MIDI","PM").replace("MATIN","AM");
		let checkvalidTime = selectTime.match(/^(0?[1-9]|1[012])(:[0-5]\d) [APap][mM]$/);
		//Checks for Valid Date.
		let rxDatePattern = /^(\d{1,2})(\/|-)(\d{1,2})(\/|-)(\d{4})$/;
		let dtArray = menuScheduleDate.match(rxDatePattern);
		let isValidDate = true;
		if (dtArray !== null) {
			//Checks for mm/dd/yyyy format.
			let dtMonth = dtArray[1];
			let dtDay = dtArray[3];
			let dtYear = dtArray[5];
			if (dtMonth < 1 || dtMonth > 12) {
				isValidDate = false;
			}
			else if (dtDay < 1 || dtDay > 31) {
				isValidDate = false;
			}
			else if ((dtMonth === 4 || dtMonth === 6 || dtMonth === 9 || dtMonth === 11) && dtDay === 31) {
				isValidDate = false;
			}
			else if (dtMonth == 2) {
				let isleap = (dtYear % 4 === 0 && (dtYear % 100 !== 0 || dtYear % 400 === 0));
				if (dtDay > 29 || (dtDay === 29 && !isleap)) {
					isValidDate = false;
				}
			}
		}

		//Checks for Date equal to greater than yesterday
		let TodayDate = new Date();
		TodayDate = TodayDate.getFullYear() + '-' + (TodayDate.getMonth() + 1) + '-' + TodayDate.getDate();
		TodayDate = new Date(TodayDate).getTime();

		let selecteddate = new Date(menuScheduleDate);
		selecteddate = selecteddate.getFullYear() + '-' + (selecteddate.getMonth() + 1) + '-' + selecteddate.getDate();
		selecteddate = new Date(selecteddate).getTime();
		// Merco vhc file selection
		if (brandId === 2 && this.state.files.length === undefined) {
			this.setState({
				loaderIconVisible: false,
				error_modal: {
					open: true,
					message: "KC1161"
				}
			});
		}
		// Merrychef & Frymaster cbr file selection
		else if ((brandId === 1 || brandId === 3 || brandId === 5 || brandId === 7 || brandId === 8 || brandId === 9 || brandId === 10) && this.state.files.length === undefined) {
			this.setState({
				loaderIconVisible: false,
				error_modal: {
					open: true,
					message: "KC1132"
				}
			});
		}
		// Convotherm sdf or db file selection
		else if (brandId === 6 && this.state.files.length === undefined) {
			this.setState({
				loaderIconVisible: false,
				error_modal: {
					open: true,
					message: "KC1130"
				}
			});
		}
		else if (this.state.ScheduleUpdate && (selectTime === "" || menuScheduleDate === "")) {
			this.setState({
				loaderIconVisible: false,
				error_modal: {
					open: true,
					startDate: todaysDate,
					message: "KC1125"
				}
			});
		}
		else if (this.state.ScheduleUpdate && !isValidDate) {
			this.setState({
				loaderIconVisible: false,
				error_modal: {
					open: true,
					startDate: todaysDate,
					message: "KC1128"
				}
			});
		}
		else if (this.state.ScheduleUpdate && !((TodayDate === selecteddate) || (TodayDate < selecteddate))) {
			this.setState({
				loaderIconVisible: false,
				error_modal: {
					open: true,
					startDate: todaysDate,
					message: "KC1127"
				}
			});
		}
		else if (this.state.ScheduleUpdate && !checkvalidTime) {
			this.setState({
				loaderIconVisible: false,
				error_modal: {
					open: true,
					message: "KC1126"
				}
			});
		}
		else {
			if (this.state.ScheduleUpdate) {
				// Current Time start
				let today = new Date();
				let hours = today.getHours();
				let minutes = today.getMinutes();
				let ampm = hours >= 12 ? 'PM' : 'AM';
				hours = hours % 12;
				hours = hours ? hours : 12;
				minutes = minutes < 10 ? '0' + minutes : minutes;
				let strTime = hours + ':' + minutes + ' ' + ampm;
				let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
				let Currentstart = date + ' ' + strTime;
				// Current Time End

				//selected Time start
				let selecteddatetime = this.state.startDate;
				let selecteddate = selecteddatetime.getFullYear() + '-' + (selecteddatetime.getMonth() + 1) + '-' + selecteddatetime.getDate();
				let selectedstart = selecteddate + ' ' + selectTime;
				//selected Time end

				//Six Month Date Validation start
				var pastfuturedate = new Date(menuScheduleDate);
				pastfuturedate = pastfuturedate.getFullYear() + '-' + (pastfuturedate.getMonth() + 1) + '-' + pastfuturedate.getDate();
				pastfuturedate = pastfuturedate + ' ' + selectTime;
				pastfuturedate = new Date(pastfuturedate).getTime();

				let max_sixmont = this.state.maxdate;
				max_sixmont = max_sixmont.getFullYear() + '-' + (max_sixmont.getMonth() + 1) + '-' + max_sixmont.getDate();
				max_sixmont = max_sixmont + ' ' + selectTime;
				max_sixmont = new Date(max_sixmont).getTime();
				//Six Month Date Validation end

				let Currenttime = new Date(Currentstart).getTime();
				let selectedtime = new Date(selectedstart).getTime();

				if (this.state.ScheduleUpdate && ((pastfuturedate > max_sixmont) || (pastfuturedate < Currenttime))) {
					this.setState({
						loaderIconVisible: false,
						error_modal: {
							open: true,
							startDate: todaysDate,
							message: "KC1125"
						}
					});
				}
				else if (this.state.ScheduleUpdate && ((Currenttime > selectedtime) || (Currenttime === selectedtime))) {
					this.setState({
						loaderIconVisible: false,
						error_modal: {
							open: true,
							message: formatMessage({ id: 'KC1124' }, { selectTime })
						}
					});
				}
				// else if (this.state.files.length > 0) {
				else {
					let scheduleDateTimeUtcFormat;
					if (this.state.ScheduleUpdate) {
						let Scheduledtime = selectTime;
						let sceduleDatewithoutString = new Date(this.state.startDate);
						const formatDate = moment(sceduleDatewithoutString, 'L').format('YYYY-MM-DD');
						const concatDateTime = `${formatDate} ${Scheduledtime}`;
						scheduleDateTimeUtcFormat = moment(concatDateTime, 'YYYY-MM-DD hh:mm A').format('YYYY-MM-DD HH:mm');
					}
					this.setState({
						scheduleDateTimeUtc: scheduleDateTimeUtcFormat,
					}, () => {
						if (this.state.typeOfTransfer === "smart-group-transfer") {
							this.onFinishClickHandler_SmartGroup();
						} else {
							this.onFinishClickHandler();
						}
					})
				}
			} else {
				if (this.state.typeOfTransfer === "smart-group-transfer") {
					this.onFinishClickHandler_SmartGroup();
				} else {
					this.onFinishClickHandler();
				}
			}
		}
	}

	handleUnitsChange(event) {
		let totalUnitsCount = this.state.userCheckedData ? this.state.userCheckedData.length : 0;
		let isInputValid = event.target.value === totalUnitsCount.toString();

		this.setState({
			enteredUnits: event.target.value,
			isInputValid: isInputValid
		});
	}

	openModalHandler = () => {
		$('#assignedSGOkModal').modal('show');
	}

	clearEnteredUnits = () => {
		this.setState({ enteredUnits: '' });
	}

	onFinishClickHandler_SmartGroup = async (e) => {
		const { formatMessage } = this.props.intl;
		const { mediaSelectedItemsInfo, locationInfo } = this.state;
		let smartGroupIds = mediaSelectedItemsInfo.SMARTGROUPS_ID;
		let smartGroupNames = mediaSelectedItemsInfo.SMARTGROUPS_NAME;

		let req = {
			"thingList": "",
			"mediaId": mediaSelectedItemsInfo.MEDIA_ID,
			"viewType": "smart-group-transfer",
			"smartGroupIds": smartGroupIds ? smartGroupIds.toString() : "",
			"smartGroupNames": smartGroupNames ? smartGroupNames.toString() : "",
			"cgid": localStorage.getItem("custGroupID"),
			//"transferType": "",
		}
		if (this.state.sheduleUpdateDetails) {
			//await this.MercoMenuAssign();
			req.scheduleDateTimeUtc = this.state.scheduleDateTimeUtc
		}
		req.transferType = this.state.autoInstall || this.state.autoInstallForSchedule ? "auto-install" : "operator-install";

		this.setState({ loaderIconVisible: true });
		this.postData(MediaManagementAPI.getUploadHistory, req, formatMessage({ id: "KC1160" })).then(response => {
			this.props.history.push(`/mediaSmartGroupStatus`);
		}).catch(err => {
			//Error Goes Here
			this.showError(err);
		});
	}

	finishHandler = () => {
		this.setState({ loaderIconVisible: true })
		const { mediaSelectedItemsInfo } = this.state;
		let { userCheckedData } = this.state;
		let totalUnitsCount = userCheckedData ? userCheckedData.length : 0;
		let offlineUnitCount = 0;
		const { formatMessage } = this.props.intl;
		let msgDesc = formatMessage({ id: "KC1160" });
		let unitList = [];

		for (let i = 0; i < userCheckedData.length; i++) {
			const unit = userCheckedData[i];
			if (unit.CONNECT_STATE === "Offline") {
				offlineUnitCount += 1
			}
			let obj = {
				"thingArn": unit.THING,
				"productId": unit.PRODUCT_ID ? unit.PRODUCT_ID : unit.ProductId,
				"cloudUnitName": unit.CLOUD_UNIT_NAME,
				"unitTimeZone": unit.TimeZone_Standard,
			}
			if (unit.useroperation) {
				obj['useroperation'] = unit.useroperation;
			}
			if (unit.useroperation !== 'skip') {
				unitList.push(obj);
			}
		}
		if (offlineUnitCount === 0) {
			msgDesc = `
				<div class="uploadCountText">
					<b>${formatMessage({ id: 'KC1783' }, { count: totalUnitsCount })}</b> \n 
					<p>${formatMessage({ id: 'KC1784' }, { count: totalUnitsCount })}</p>
				</div>
				`
		} else if (totalUnitsCount > 0 && offlineUnitCount > 0) {
			msgDesc = `
				<div class="uploadCountText">
				<b>${formatMessage({ id: 'KC1783' }, { count: totalUnitsCount })}<br></b>
				<p>  ${formatMessage({ id: 'KC1785' }, { count: offlineUnitCount })}</p>
				</div>
				`
		}

		let req = {
			"thingList": unitList,
			"mediaId": mediaSelectedItemsInfo.MEDIA_ID,
		}

		if (this.state.sheduleUpdateDetails) {
			req.scheduleDateTimeUtc = this.state.scheduleDateTimeUtc
		}

		req.transferType = this.state.autoInstall || this.state.autoInstallForSchedule ? "auto-install" : "operator-install";
		this.setState({ loaderIconVisible: true });

		this.postData(MediaManagementAPI.getUploadHistory, req, msgDesc).then(response => {
			this.props.history.push(`/mediaStatus`);
		}).catch(err => {
			this.showError(err);
		});

	}

	onFinishClickHandler = async (e) => {
		const { mediaSelectedItemsInfo } = this.state;
		let userCheckedData = this.state.userCheckedData;
		let overWriteAvailable = false;

		for (let i = 0; i < userCheckedData.length; i++) {
			const unit = userCheckedData[i];
			if (mediaSelectedItemsInfo.FILE_SIZE && Number(mediaSelectedItemsInfo.FILE_SIZE) > unit.AVAILABLE_SPACE) {
				userCheckedData[i].useroperation = 'overwrite';
				overWriteAvailable = true;
			}
		}

		if (overWriteAvailable) {
			this.setState({
				userCheckedData,
				loaderIconVisible: false
			}, () => {
				$('#overwriteMediaModal').modal('show');
				return;
			})
		} else {
			this.finishHandler();
		}
	}

	postData = (url, reqData, msgDesc) => {
		this.setState({
			loaderIconVisible: true
		});
		return new Promise((resolve, reject) => {

			http.post(url
				, reqData,
				{
					headers: {
						'Content-Type': 'application/json',
						'customer': localStorage.getItem("customerId"),
						'user': localStorage.getItem("user"),
						'basecountry': BASECOUNTRY_ID,
						'brandid': localStorage.getItem("unitBrandId"),
					}
				}
			)
				.then(response => {
					let { data } = response;
					const { formatMessage } = this.props.intl;
					let { httpCode, responseCode, message } = data;
					let MSmsg = response.data.stringID ? formatMessage({ id: response.data.stringID }) : response.data.message;
					if (response && response.status === 200) {
						WoopraEvents(`${Constants.FILE_TRANSFERED}`);
						this.setState({
							loaderIconVisible: false,
							success_modal: {
								open: true,
								message: (MSmsg || reqData.thingList.length > 100 ? msgDesc : "KC2227"),
								isHtml: MSmsg || reqData.thingList.length > 100 ? true : false
							},
						});
					}
				})
				.catch(err => {
					reject(err);
				});
		});
	}

	UpdateNow = (e) => {
		this.setState({
			autoInstall: true,
			sheduleUpdateDetails: false,
		})
	}

	findSelection = (e) => {
		let val = e.target.value;
		this.setState({
			autoInstall: val === "AutoInstall" ? true : false,
			autoInstallForSchedule: val === "AutoInstallForSchedule" ? true : false,
			sheduleUpdateDetails: val === "AutoInstallForSchedule" || val === "OperatorInstallForSchedule" ? true : false,
		})
	}

	findSheduleUpdateDetails = (e) => {
		this.setState({
			autoInstallForSchedule: true,
			sheduleUpdateDetails: e.target.checked,
		})
	}

	showError = (err) => {
		let { response } = err;
		this.setState({
			loaderIconVisible: false,
			error_modal: {
				open: true,
				message: "KC1110",
			},
		});
	}

	changeRadioButton = (ID, value) => {
		let { userCheckedData } = this.state;
		const findIndex = userCheckedData.findIndex((i) => i.ID === ID);
		userCheckedData[findIndex].useroperation = value;
		this.setState({ userCheckedData });
	}

	checkSelectedAll = (userCheck, value) => {
		const filterVal = userCheck.filter((i) => i.useroperation === value);
		return filterVal.length === userCheck.length ? true : false;
	}

	checkAll = (value) => {
		let { userCheckedData } = this.state;
		for (let i = 0; i < userCheckedData.length; i++) {
			if (userCheckedData[i].useroperation) {
				userCheckedData[i].useroperation = value;
			}
		}
		this.setState({ userCheckedData });
	}

	render() {
		const { formatMessage } = this.props.intl;
		const { loaderIconVisible, userCheckedData, typeOfTransfer, totalUnitsCount } = this.state;
		let { success_modal, error_modal } = this.state;
		let callSmartGroupFinish = false;
		let units = userCheckedData ? userCheckedData : [];
		units = UnitBuilder(units);
		if (typeOfTransfer === "smart-group-transfer") {
			callSmartGroupFinish = true;
		}


		return (

			<div id="adminWrapper">
				<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
					<div className="loadingProgressIn"></div>
				</div>
				<div id="page-content-wrapper">
					<div class="softwareUpdateWrapper">
						<div class="adminBgHeader">
							<ul>
								<li>
									<Link to={{ pathname: callSmartGroupFinish ? this.props.location.previouspath : "/assignmedialistView", previouspath: this.props.location.previouspath }} title={formatMessage({ id: 'KC0989' })} className="backIcon">&nbsp;</Link>
								</li>
								<li><FormattedMessage id="KC1156" /></li>
								<li>
									<button id="btnnext" onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} className="btn-default-text btn forwardNext activeState" onClick={(e) => this.openModalHandler(e)} title={formatMessage({ id: 'KC0056' })}><FormattedMessage id="KC0056" /></button>
								</li>
							</ul>
						</div>
						<div className="scheduleUpdateWrapper">
							<div className="scheduleUpdateWrapper1">
								<div className="scheduleUpdateOption" onChange={this.setscheduleUpdate.bind(this)}>
									<div className="tester">
										<div className="customRadioBtn">
											<input id="UpdateNow" onClick={(e) => this.UpdateNow(e)} checked={this.state.UpdateNow} type="radio" name="ScheduleUpdateOption" value="UpdateNow" />
											<label for="UpdateNow"><FormattedMessage id="KC0666" /></label>
										</div>

										<div id="Schedulediv1" className="scheduleUpdateForm displayblock reportTypeListCBox">
											<ul>
												<li>
													<div className="customRadioBtn">
														<input id="AutoInstall" onClick={(e) => this.findSelection(e)} checked={this.state.autoInstall} type="radio" name="AutoInstall" value="AutoInstall" />
														<label for="AutoInstall"><FormattedMessage id="KC0744" /></label>
													</div>
													<button type="button" className="btn reportTypeInfo infoIcon" data-toggle="modal" data-target="#autoInfoModal">&nbsp;</button>
												</li>
												{this.state.brandId === 14 ? "" : <li>
													<div className="customRadioBtn disabled">
														<input id="OperatorInstall" onClick={(e) => this.findSelection(e)} checked={!this.state.autoInstall} type="radio" name="OperatorInstall" value="OperatorInstall" />
														<label for="OperatorInstall"><FormattedMessage id="KC0746" /></label>
													</div>
													<button type="button" className="btn reportTypeInfo infoIcon" data-toggle="modal" data-target="#operatorInfoModal">&nbsp;</button>
												</li>}
											</ul>
										</div>
									</div>

									<div className="tester">
										<div className="customRadioBtn">
											<input id="ScheduleUpdate" checked={this.state.sheduleUpdateDetails} onClick={(e) => this.findSheduleUpdateDetails(e)} type="radio" name="ScheduleUpdateOption" value="ScheduleUpdate" />
											<label for="ScheduleUpdate"><FormattedMessage id="KC0665" /></label>
										</div>

										<div id="Schedulediv" className="scheduleUpdateForm displaynone reportTypeListCBox">
											<ul>
												<li>
													<label><FormattedMessage id="KC0749" /></label>
													<div className="input-group">
														<DatePicker locale={datePickerLocale} disabledKeyboardNavigation id="ScheduleDate" onKeyDown={(e) => this.ResetDatepicker(e)} autoComplete="off" showMonthDropdown showYearDropdown dropdownMode="select" selected={this.state.startDate} placeholderText={formatMessage({ id: 'KC0835' })} onChange={this.handleChange} minDate={new Date()} maxDate={this.state.maxdate} />
													</div>
												</li>
												<li>
													<label><FormattedMessage id="KC0750" /></label>
													<div className="input-group" id="timeSchedulePanel" onChange={(e) => this.settimeselectonmenu(e)}>
														<input type="text" autoComplete="off" placeholder={formatMessage({ id: 'KC0836' })} name="selectTime" id="selectTime" maxlength="8" onkeydown="myFunction(event)" />
														<ul className="dropdownTimeOptions">
															{thirty_Mnts_Interval.map((value) =>
																<li><a href="javascript:void(0)" data-value={value}><TimePeriodFormatter text={value} /></a></li>
															)}
														</ul>
													</div>
												</li>
												<div className="clearfix"></div>
												<li>
													<div className="customRadioBtn">
														<input id="AutoInstallForSchedule" onClick={(e) => this.findSelection(e)} checked={this.state.autoInstallForSchedule} type="radio" name="AutoInstallForSchedule" value="AutoInstallForSchedule" />
														<label for="AutoInstallForSchedule"><FormattedMessage id="KC0744" /></label>
													</div>
													<button type="button" className="btn reportTypeInfo infoIcon" data-toggle="modal" data-target="#autoInfoModal">&nbsp;</button>
												</li>
												<li>
													<div className="customRadioBtn disabled">
														<input id="OperatorInstallForSchedule" onClick={(e) => this.findSelection(e)} checked={!this.state.autoInstallForSchedule} type="radio" name="OperatorInstallForSchedule" value="OperatorInstallForSchedule" />
														<label for="OperatorInstallForSchedule"><FormattedMessage id="KC0746" /></label>
													</div>
													<button type="button" className="btn reportTypeInfo infoIcon" data-toggle="modal" data-target="#operatorInfoModal">&nbsp;</button>
												</li>
											</ul>
										</div>
									</div>

								</div>


							</div>
						</div>
					</div>
				</div>
				<ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
				<SuccessMoal open={success_modal.open} stringID={success_modal.message} isHtml={success_modal.isHtml} onSuccessModel={this.onSuccessCloseModal} />
				<UserManagementHeader headerName="Media Management - Crem Library" headerNameStringID="KC1925" activeClass="mediaNav" />
				{/* Unit Confirmation Modal */}
				<div className="modal fade confirmModalSM" data-keyboard="true" data-backdrop="static" id="assignedSGOkModal" tabindex="-1" role="dialog">
					<div className="modal-dialog modal-md">
						<div className="modal-content">
							<div className="modal-body">
								<div className="confirmMContententD">
									<div className="h2Title warningInfo">{formatMessage({ id: 'KC2665' }).replace("{{number}}",totalUnitsCount)}</div>
									<p>{formatMessage({ id: 'KC2666' }).replace("{{number}}",totalUnitsCount)}<br />
										<span class="textBold"><FormattedMessage id="KC2667" /></span><br />
										<FormattedMessage id="KC2668" /></p>
									<div className="textWformF"><FormattedMessage id="KC0102" />&nbsp;<span>'{totalUnitsCount}'</span>&nbsp;<FormattedMessage id="KC2932" /> &nbsp;
										<input
											type="text"
											className={`form-control ${!this.state.isInputValid && this.state.enteredUnits !== '' ? 'errorField' : ''} ${this.state.isInputValid ? 'valueField' : ''}`}
											value={this.state.enteredUnits}
											onChange={this.handleUnitsChange}
										/>
										<div style={{ height: '20px' }}>
											{!this.state.isInputValid && this.state.enteredUnits !== '' && (
												<div class="required"><FormattedMessage id="KC2670" /></div>
											)}
										</div>
									</div>
								</div>
							</div>
							<div className="modal-footer textAlignCenter">
								<button className="btn confirmYes" data-dismiss="modal" type="button" onClick={() => this.setState({ isInputValid: false })}><FormattedMessage id="KC0580"/></button>
								<button className="btn confirmNo" data-dismiss="modal" type="button" disabled={!this.state.isInputValid} onClick={(e) => this.MercoMenuAssign(e)}><FormattedMessage id="KC0611"/></button>							</div>
						</div>
					</div>
				</div>
				{/* <!-- Info Modal --> */}
				<div className="modal fade infoModal" data-keyboard="true" data-backdrop="static" id="autoInfoModal" tabIndex="-1" role="dialog" aria-labelledby="detailsInfoModalLabel">
					<div className="modal-dialog modal-md infoModal">
						<div className="modal-content">
							<div className="modal-header modal-header-danger">
								<FormattedMessage id="KC0744" /> <button type="button" className="close" data-dismiss="modal" aria-label="Close">&nbsp;</button>
							</div>
							<div className="modal-body">
								<div className="detailsInfoContent">
									<p><FormattedMessage id="KC0745" /></p>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="modal fade infoModal" data-keyboard="true" data-backdrop="static" id="operatorInfoModal" tabIndex="-1" role="dialog" aria-labelledby="detailsInfoModalLabel">
					<div className="modal-dialog modal-md infoModal">
						<div className="modal-content">
							<div className="modal-header modal-header-danger">
								<FormattedMessage id="KC0746" />   <button type="button" className="close" data-dismiss="modal" aria-label="Close">&nbsp;</button>
							</div>
							<div className="modal-body">
								<div className="detailsInfoContent">
									<p><FormattedMessage id="KC0747" /></p>
								</div>
							</div>
						</div>
					</div>
				</div>


				{/* Overwrite Modal */}
				<div className="modal fade overwriteMediaModal" data-keyboard="true" data-backdrop="static" id="overwriteMediaModal"
					tabIndex="-1" role="dialog" aria-labelledby="overwriteMediaModalLabel">
					<div className="modal-dialog modal-md">
						<div className="modal-content">
							<div className="modal-body" style={{ 'max-height': 'calc(100vh - 210px)', 'overflow-y': 'auto' }}>
								<div className="overwriteMedia">
									<div className="overwriteMediaHeader">
										<ul>
											<li><img src={require('../../../images/icons/AttentionYellow.svg').default} /></li>
											<li>
												<p>{formatMessage({ id: 'KC1032' })}</p>
												<p>{formatMessage({ id: 'KC1033' })}</p>
											</li>
										</ul>
									</div>
									<div className="mediaOverwriteTable">
										<ul className="tHead">
											<li>#</li>
											<li><FormattedMessage id="KC0101" /></li>
											<li><FormattedMessage id="KC0104" /></li>
											<li>
												<div className="customRadioBtn">
													<input id="OverwriteAll" type="radio" name="selectAll" checked={this.checkSelectedAll(units.filter((i) => i.useroperation), 'overwrite')} onChange={() => this.checkAll('overwrite')} value="OverwriteAll" />
													<label for="OverwriteAll"><FormattedMessage id="KC1034" /></label>
												</div>
											</li>
											<li>
												<div className="customRadioBtn">
													<input id="SkipAll" type="radio" name="selectAll" checked={this.checkSelectedAll(units.filter((i) => i.useroperation), 'skip')} onChange={() => this.checkAll('skip')} value="SkipAll" />
													<label for="SkipAll"><FormattedMessage id="KC1035" /></label>
												</div>
											</li>
										</ul>
										{units.filter((i) => i.useroperation).map((unitItem, i) => {
											return (
												<ul className="tBody" key={i}>
													<li>{i + 1}</li>
													<li>
														<span className="unitImg"><img alt={unitItem.Brand_Alt} src={unitItem.Brand_Logo} /></span>
														<span className="unitName">{unitItem.BRAND_NAME} <b>{unitItem.CLOUD_UNIT_NAME}</b></span>
													</li>
													<li>{unitItem.UNITID}</li>
													<li>
														<div className="customRadioBtn">
															<input id={`Overwrite${unitItem.ID}`} name={`selectrow${unitItem.ID}`} onChange={() => this.changeRadioButton(unitItem.ID, 'overwrite')} type="radio" checked={unitItem.useroperation === "overwrite"} value="Overwrite" />
															<label for={`Overwrite${unitItem.ID}`}></label>
														</div>
													</li>
													<li>
														<div className="customRadioBtn">
															<input id={`Skip${unitItem.ID}`} type="radio" name={`selectrow${unitItem.ID}`} onChange={() => this.changeRadioButton(unitItem.ID, 'skip')} checked={unitItem.useroperation === "skip"} value="Skip" />
															<label for={`Skip${unitItem.ID}`}></label>
														</div>
													</li>
												</ul>
											)
										})}
									</div>
								</div>
							</div>
							<div className="modal-footer textAlignCenter">
								<button className="btn btn-secondary submitBtn" data-dismiss="modal" onClick={() => this.finishHandler()} type="button"><FormattedMessage id="KC0022" /></button>
							</div>
						</div>
					</div>
				</div>


			</div>
		)
	}
};

function mapStateToProps(state) {
	return {
		filterlistInformtion: state.filterModel,
		mediaSelectedItemsInfo: state.mediaPreparationListInformation.mediaSelectedItems,
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MediaTransferUnits));