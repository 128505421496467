import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import UserManagementHeader from '../../layoutSection/userManagementHeader'
import { getSoftwareUpdateStatus } from '../../../actions/navigation/softwareManagement/softwareUpdateStatusActions'
import { TableHeader } from '../../controls/tableHeader';
import _ from 'lodash';
import http from '../../../service/httpService';
import { SoftwareManagementAPI, MediaManagementAPI } from '../../../service/api';
import { BASECOUNTRY_ID, MEDIA_SPEC } from '../../../utils/appConstants';
import ErrorMoal from '../../modal/error-model';
import SuccessMoal from '../../modal/success-model';
import momentHelper from '../../../utils/momentHelper.js';
import { UnitBuilder } from '../../../utils/unitResponseBuilder';
import NoRecordsFound from '../../controls/noRecordsFound';
import { Paginator } from '../../controls/paginator';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import authService from '../../../service/authService';
import Tooltip from 'react-tooltip-lite';
import WoopraEvents from '../../../woopraConfig/WoopraEvents';
import Constants from '../../../woopraConfig/Woopraconstant';
import { HOVER_TEXT_SCHEDULED, HOVER_TEXT_QUEUED, HOVER_TEXT_UPDATING, HOVER_TEXT_EXPIRED, HOVER_TEXT_COMPLETED, HOVER_TEXT_CANCELED } from '../../../utils/appConstants';
import { FormattedMessage, injectIntl } from 'react-intl';
import { datePickerLocale } from '../../../locale/constant';
import $ from 'jquery';
import { searchVisible, searchText, clearSearchText } from '../../../actions/advanceSearch/advanceSearchAction';
import { isEqualObj, searchData_function } from '../../globalSearchBar/selectOption';
import GoToPagination from '../../controls/goToPagination';
var moment = require('moment');

let todaysDate = new Date();
let Yesterdaydate = new Date(todaysDate.setDate(todaysDate.getDate()));
let sixMonthsFromNow = new Date(todaysDate.setMonth(todaysDate.getMonth() - 6));
sixMonthsFromNow = sixMonthsFromNow.setDate(sixMonthsFromNow.getDate() - 1);
let savestartDate = new Date(todaysDate.setMonth(todaysDate.getMonth() + 5));
let firstTimeDate = savestartDate;
firstTimeDate = firstTimeDate.setDate(firstTimeDate.getDate() - 1);
let saveendDate = Yesterdaydate;

class MediaStatus extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loaderIconVisible: false,
			sortColumn: { path: "", order: "", sort_color: "#63778a" },
			success_modal: {
				open: false,
				message: ""
			},
			error_modal: {
				open: false,
				message: ""
			},
			unitModelDetails: [],
			unitLocationDetails: [],
			unitSoftwareDetails: [],
			Scheduled: true,
			Queued: true,
			Updating: true,
			Pending: false,
			Completed: true,
			Canceled: true,
			Failed: true,
			distributionCounts: [],
			deleteIcon: false,
			cancelIcon: false,
			retryIcon: false,
			currentPage: 1,
			pageSize: 25,
			totalPages: null,
			startDate: localStorage.getItem('startDate') ? moment(localStorage.getItem('startDate')).toDate() : firstTimeDate,
			endDate: localStorage.getItem('endDate') ? moment(localStorage.getItem('endDate')).toDate() : Yesterdaydate,
			maxdate: Yesterdaydate,
			minDate: sixMonthsFromNow,
			searchData: null,
			showToast: false,
			firstLoad: false,
			brandId: []
		};
	}

	componentWillMount = async () => {
		await this.getStatusDetails();
	}

	getStatusDetails = async () => {
		let statusType = localStorage.getItem("statusType");
		if (statusType) {
			this.setState({
				Scheduled: false,
				Queued: false,
				Updating: false,
				Pending: false,
				Completed: false,
				Canceled: false,
				Failed: false,
			})

			if (statusType === "IN_PROGRESS") {
				this.setState({
					Queued: true,
					Updating: true,
					Pending: true,
				})

			} else if (statusType === "SUCCEEDED") {
				this.setState({
					Completed: true,
				})
			} else if (statusType === "CANCELED") {
				this.setState({
					Canceled: true,
				})
			} else if (statusType === "REJECTED,FAILED") {
				this.setState({
					Failed: true,
				})
			}
		}
	}

	updateDimension() {
		var windowHeight = $(window).height();
		var tableHNav = $(".tableHNav").height();
		var selectedUnitHeader = $(".selectedUnitHeader").height();
		var statusTableH = $(".statusTable .tHead").height();
		var statusTableSrlBarH = ((windowHeight) - (tableHNav + selectedUnitHeader + statusTableH)) - 70;
		$(".statusTableSrlBar").css("height", statusTableSrlBarH);
		const $tableBody = $('.statusTableSrlBar');
		const $tableBodyBottom = $('.paginationDWgoto, .paginationWgoto').height() ? $('.paginationDWgoto, .paginationWgoto').height() + 18 : 0;
		$tableBody.css('height', windowHeight - ($tableBody.offset().top + $tableBodyBottom));
	}

	componentDidMount = async () => {
		this.setState({
			firstLoad: true
		});
		window.addEventListener('resize', this.updateDimension);
		document.body.style.backgroundColor = "#f2f2f2";
		this.props.searchVisible(true);
		let brandIdArr = [];
		MEDIA_SPEC.forEach((x) => {
			if(x.showStatus){
				brandIdArr.push(x.BRAND_ID)
			}
		})
		this.setState({
			brandId: brandIdArr
		});
		await this.getCount();
		this.getSoftwareUpdateStatus();
		this.interval = setInterval(() => { this.getCount(); this.getSoftwareUpdateStatus() }, 30000);
	}

	closeToast = () => {
		this.setState({ showToast: false });
	}

	componentDidUpdate = (prevState) => {
		let { searchData } = prevState;
		let curSearchData = this.state && this.state.searchData;
		if (curSearchData && (isEqualObj(searchData, curSearchData) === false)) {
			this.setState({
				currentPage: 1
			}, () => {
				this.getSoftwareUpdateStatus(1);
				this.getCount();
			})
		}
	}

	static getDerivedStateFromProps = (props) => ({ ...props });

	getUnitPopUpDetails = async (item) => {
		this.setState({
			loaderIconVisible: true
		});
		await this.getUnitModelDetails(item);
		await this.getUnitLocationDetails(item);
		await this.getUnitSoftwareDetails(item);
		this.setState({
			loaderIconVisible: false
		});
	}

	getUnitModelDetails = async (item) => {
		let url = SoftwareManagementAPI.unitModelInfo;
		url = url + item.UNITID + "/" + item.BRAND + "/device-info";
		return new Promise((resolve, reject) => {
			http.get(url, {
				headers: {
					'Content-Type': 'application/json',
				},
				data: {}
			}).then(response => {
				let val = response.data;
				val.FILE_NAME = item.FILE_NAME ? item.FILE_NAME : "";
				this.setState({
					unitModelDetails: val,

				});
				resolve(response.data);
			}).catch(err => {
				reject(err);
			});
		});
	}

	getUnitLocationDetails = async (item) => {
		let url = SoftwareManagementAPI.unitLocationInfo;
		url = url + item.UNITID + "/" + item.BRAND;
		return new Promise((resolve, reject) => {
			http.get(url, {
				headers: {
					'Content-Type': 'application/json',
				},
				data: {}
			}).then(response => {
				let details = response.data.units;
				let unitDetails = [];
				let val = {
					"BRAND_NAME": details.BRAND_NAME,
					"MODEL_NAME": details.MODEL_NAME
				}
				unitDetails.push(val);
				let logoDetails = UnitBuilder(unitDetails);
				details.Brand_Alt = logoDetails[0].Brand_Alt;
				details.Brand_Logo = logoDetails[0].Brand_Logo;
				details.Brand_Title = logoDetails[0].Brand_Title;

				this.setState({
					unitLocationDetails: details,//response.data.units,
				});

				resolve(response.data);
			}).catch(err => {
				reject(err);
			});
		});
	}

	getUnitSoftwareDetails = async (item) => {

		let url = SoftwareManagementAPI.unitSoftwareInfo;
		return new Promise((resolve, reject) => {
			http.get(url, {
				headers: {
					'Content-Type': 'application/json',
					'cloudUnitName': item.CLOUD_UNIT_NAME,
				},
				data: {}
			}).then(response => {
				this.setState({
					unitSoftwareDetails: response.data
				});
				resolve(response.data);
			}).catch(err => {
				reject(err);
			});
		});
	}

	getSoftwareUpdateStatus = (currentPage = this.state.currentPage) => {
		this.setState({
			loaderIconVisible: true
		});
		let custGroupID = localStorage.getItem("custGroupID");

		let cgids = "";
		if (custGroupID != "" && custGroupID != undefined) {
			cgids = custGroupID;
		}
		let responseResult = {
			data: []
		}

		let { Scheduled, Queued, Updating, Pending, Completed, Failed, Canceled } = this.state;
		let filtertypeVa = "";
		if (Scheduled) {
			if (filtertypeVa) {
				filtertypeVa = filtertypeVa + "," + "Scheduled";
			} else {
				filtertypeVa = "Scheduled";
			}
		}

		if (Queued) {
			if (filtertypeVa) {
				filtertypeVa = filtertypeVa + "," + "Queued";
			} else {
				filtertypeVa = "Queued";
			}
		}

		if (Updating || Pending) {
			if (filtertypeVa) {
				filtertypeVa = filtertypeVa + "," + "IN_PROGRESS";
			} else {
				filtertypeVa = "IN_PROGRESS";
			}
		}

		if (Completed) {
			if (filtertypeVa) {
				filtertypeVa = filtertypeVa + "," + "SUCCEEDED";
			} else {
				filtertypeVa = "SUCCEEDED";
			}
		}

		if (Canceled) {
			if (filtertypeVa) {
				filtertypeVa = filtertypeVa + "," + "CANCELED";
			} else {
				filtertypeVa = "CANCELED";
			}
		}

		if (Failed) {
			if (filtertypeVa) {
				filtertypeVa = filtertypeVa + "," + "REJECTED,FAILED";
			} else {
				filtertypeVa = "REJECTED,FAILED";
			}
		}

		let headerVal = {
			'Content-Type': 'application/json',
			// 'basecountry': BASECOUNTRY_ID,
			'user': localStorage.getItem("user"),
			'customerId': localStorage.getItem("customerId"),
			'brandId': this.state.brandId.toString(),//localStorage.getItem("unitBrandId"),
			'cgids': cgids,
			'filtertype': filtertypeVa ? filtertypeVa : "UNSELECTALL",
			'startdate': this.state.startDate ? format(this.state.startDate, 'YYYY-MM-DD') : "",
			'enddate': this.state.endDate ? format(this.state.endDate, 'YYYY-MM-DD') : "",
		};

		let url = MediaManagementAPI.getUploadHistory;
		let pageNumber = '?pageNum=' + currentPage;
		let pageLimit = '&pageSize=' + this.state.pageSize;

		url = url + pageNumber + pageLimit;
		let { searchData } = this.state;
		//searchData_function
		let _search = searchData_function(searchData);
		if (_search) {
			url = `${url}${_search}`;
		}

		http.get(url, {
			headers: headerVal,
			data: {}
		}).then(async response => {
			if (response && response.data) {
				WoopraEvents(`${Constants.STATUS_FILTER_APPLIED}`, filtertypeVa);
				let arr = [];
				let isDelete = false;
				for (let i = 0; i < response.data.mediaDistributeResponse.mediaDistributeList.length; i++) {
					const ele = response.data.mediaDistributeResponse.mediaDistributeList[i];
					isDelete = await this.checkDeleteAccess(ele);
					arr.push({ ...ele, isAuthorized: isDelete })
				};
				this.setState({
					loaderIconVisible: false,
					mediaUpdateStatusInformation: arr,
					totalPages: response.data.mediaDistributeResponse && response.data.mediaDistributeResponse.numberOfPages ? response.data.mediaDistributeResponse.numberOfPages : 0,
					currentPage
				}, () => {
					if (this.props.location.state && this.props.location.state.showToast && this.state.firstLoad) {
						this.setState({ showToast: true, firstLoad: false });
						setTimeout(() => {
							this.setState({ showToast: false });
						}, 5000);
					}
				});
			}
		}).catch(err => {
			this.setState({
				loaderIconVisible: false,
				mediaUpdateStatusInformation: [],
				totalPages: 0
			});
		});
	}

	checkDeleteAccess = async (mediaValue) => {
		const { username } = JSON.parse(localStorage.getItem('loginInfo'));
		const roleName = localStorage.getItem('roleName') || '';

		let isDelete = false;
		if (mediaValue.DISTRIBUTED_BY && mediaValue.DISTRIBUTED_BY.toString().match('@')) {
			isDelete = roleName.toLowerCase() === 'customer admin' || username === mediaValue.DISTRIBUTED_BY ? true : false;
		} else {
			isDelete = roleName.toLowerCase() === 'customer admin' ? true : false;
		}
		if (isDelete === false) {
			return isDelete;
		}

		return isDelete;
	}

	ResetDatepicker = (e) => {
		e.preventDefault();
		return false;
	}

	handleChangestart = (date) => {
		savestartDate = date;
		this.setState({
			startDate: date,
		}, () => {
			localStorage.setItem('startDate', date);
			if (savestartDate > saveendDate) {
				this.setState({
					error_modal: {
						open: true,
						message: "KC1581"
					}
				});
			} else {
				WoopraEvents(`${Constants.DATE_APPLIED}`);
				this.getCount()
				this.getSoftwareUpdateStatus(1)
			}
		});

	}

	handleChangeend = (date) => {
		saveendDate = date;
		this.setState({
			endDate: date,
		}, () => {
			localStorage.setItem('endDate', date);
			if (savestartDate > saveendDate) {
				this.setState({
					error_modal: {
						open: true,
						message: "KC1120"
					}
				});
			} else {
				this.getCount();
				this.getSoftwareUpdateStatus(1)
			}
		});
	}

	getCount = async () => {
		this.setState({
			loaderIconVisible: true
		});
		let custGroupID = localStorage.getItem("custGroupID");

		let cgids = "";
		if (custGroupID != "" && custGroupID != undefined) {
			cgids = custGroupID;
		}

		let filtertypeVa = "Scheduled,Queued,IN_PROGRESS,SUCCEEDED,REJECTED,FAILED,CANCELED";

		let headerVal = {
			'Content-Type': 'application/json',
			// 'basecountry': BASECOUNTRY_ID,
			'user': localStorage.getItem("user"),
			'customerId': localStorage.getItem("customerId"),
			'brandId': this.state.brandId.toString(),//localStorage.getItem("unitBrandId"),
			'cgids': cgids,
			"filtertype": filtertypeVa,
			'startdate': this.state.startDate ? format(this.state.startDate, 'YYYY-MM-DD') : "",
			'enddate': this.state.endDate ? format(this.state.endDate, 'YYYY-MM-DD') : "",
		};

		let url = MediaManagementAPI.getUploadHistory;
		let { searchData } = this.state;
		//searchData_function
		let _search = searchData_function(searchData);
		if (_search) {
			url = `${url}?${_search}`;
		}
		http.get(url, {
			headers: headerVal,
			data: {}
		}).then(response => {
			if (response && response.data) {
				this.setState({
					distributionCounts: response.data.mediaDistributeResponse.statusCount
				});
			}
		}).catch(err => {
			this.setState({
				distributionCounts: [],
			});
		});
	}

	componentWillUnmount() {
		localStorage.removeItem("statusType");
		localStorage.removeItem('startDate');
		localStorage.removeItem('endDate');
		this.props.searchText({});
		this.props.searchVisible(false);
	}

	// clear Global Search
	clearGlobalSearch = () => {
		this.props.clearSearchText();
	}

	onSort = sortColumn => {
		this.setState({
			sortColumn: sortColumn
		});
	}

	closeModel = (err) => {
		this.setState({
			loaderIconVisible: false,
			error_modal: {
				open: false,
				message: ""
			},
			deleteIcon: false,
			cancelIcon: false,
			retryIcon: false
		});
	}

	onSmartGroupClick() {
		WoopraEvents(`${Constants.SMARTGROUP_STATUS}`);
		this.props.history.push({
			pathname: "/mediaSmartGroupStatus",
		})
	}

	onSuccessCloseModal = async (e) => {
		this.setState({
			distributionCounts: [],
			success_modal: {
				open: false
			},
			deleteIcon: false,
			cancelIcon: false,
			retryIcon: false
		})
		await this.getCount();
		this.getSoftwareUpdateStatus(1);
	}

	onRetry = () => {
		this.setState({ loaderIconVisible: true });
		let selSoftwareId = [];
		let selId = [];
		const unitList = [];
		let mediaIds = [];
		let req = {};
		let reqData;
		var rolesList = [...this.state.mediaUpdateStatusInformation];
		rolesList = rolesList.filter(item => item.checked === true);
		rolesList.map(item => {
			unitList.push(
				{
					"thingArn": item.THING_ARN,
					// "modelName": item.MODEL_NAMES,
					"modelNumber": item.MODEL_NO,
					"cloudUnitName": item.CLOUD_UNIT_NAME,
					"unitTimeZone": item.UNIT_TIME_ZONE,
					"distributionId": item.ID,
					"mediaId": item.MEDIA_ID,
				}
			)
			mediaIds.push(
				item.MEDIA_ID
			)
			req =
			{
				"thingList": unitList,
				"mediaId": mediaIds,
				// "modelName": item.MODEL_NAMES,
				// "description": item.DESCRIPTION,
				"transferType": item.TRANSFER_TYPE,
				"isMultiretry": true,
			}
		});

		let url = MediaManagementAPI.getUploadHistory;
		http.post(url, req, {
			headers: {
				'Content-Type': 'application/json',
				'customer': localStorage.getItem("customerId"),
				'user': localStorage.getItem("user"),
				'basecountry': BASECOUNTRY_ID,
				'brandid': this.state.brandId.toString(),
			},
			data: reqData,
		}).then(response => {
			let { data } = response;
			this.setState({
				curr_Prod_ID: "",
				curr_Prod_Name: "",
				loaderIconVisible: false,
				success_modal: {
					open: true,
					message: "KC1500"
				},
			});
			WoopraEvents(`${Constants.RETRY_DISTRIBUTION}`, req);
		}).catch(err => {
			this.setState({
				loaderIconVisible: false,
				error_modal: {
					open: true,
					message: err.response.data.message,
				},
			});
		});
	}

	onDelete = (oprType) => {
		const { formatMessage } = this.props.intl;
		this.setState({ loaderIconVisible: true });
		let selSoftwareId = [];
		let selId = [];
		let reqData;
		var rolesList = [...this.state.mediaUpdateStatusInformation];
		rolesList.forEach(i => {
			if (i.checked === true) {
				selSoftwareId.push(i.MEDIA_FK);
				selId.push(i.ID);
			}
		});

		reqData = {
			'mediaIdsToDelete': selSoftwareId,
			'id': selId,
		}
		let url = SoftwareManagementAPI.mediaListStatusDelete;
		http.delete(url, {
			headers: {
				'Content-Type': 'application/json',
				'Operation': oprType,
			},
			data: reqData,
		}).then(response => {
			let { data } = response;
			this.setState({
				curr_Prod_ID: "",
				curr_Prod_Name: "",
				loaderIconVisible: false,
				success_modal: {
					open: true,
					message: oprType === "delete" ? formatMessage({ id: 'KC1501' }) : formatMessage({ id: 'KC1502' }),
				},
			});
			if (oprType === "delete") {
				WoopraEvents(`${Constants.DELETE_DISTRIBUTION}`, reqData);
			} else {
				WoopraEvents(`${Constants.CANCEL_DISTRIBUTION}`, reqData);
			}
		}).catch(err => {
			this.setState({
				loaderIconVisible: false,
				error_modal: {
					open: true,
					message: err.response && err.response.data ? err.response.data.message : "KC1110",
				},
			});
		});
	}

	onSelectRole = (e, item) => {
		if (this.state.mediaUpdateStatusInformation && this.state.mediaUpdateStatusInformation.length > 0) {
			const rolesList = [...this.state.mediaUpdateStatusInformation];
			const index = rolesList.indexOf(item);
			rolesList[index] = { ...rolesList[index] };
			rolesList[index].checked = rolesList[index].isAuthorized ? e.target.checked : false;
			this.setState({
				mediaUpdateStatusInformation: rolesList,
			}, () => {
				this.controlTheButtonClick(rolesList);
			});
		}
	}

	controlTheButtonClick = (SelData) => {
		this.setState({
			cancelIcon: false,
			deleteIcon: false,
			retryIcon: false,
		});
		let selectedData = SelData.filter(item => item.checked === true && item.isAuthorized);
		let selScheduledCtr = 0, selQueuedCtr = 0, selUpdatingCtr = 0, selPendingCtr = 0, selCompletedCtr = 0, selFailedCtr = 0, selCanceledCtr = 0;
		selectedData && selectedData.forEach(item => {
			selQueuedCtr += item.DISTRIBUTION_STATE === 'Queued' ? 1 : 0;
			selUpdatingCtr += item.DISTRIBUTION_STATE === 'IN_PROGRESS' ? 1 : 0;
			selPendingCtr += item.DISTRIBUTION_STATE === 'IN_PROGRESS' ? 1 : 0;
			selScheduledCtr += item.DISTRIBUTION_STATE === 'Scheduled' ? 1 : 0;
			selCompletedCtr += item.DISTRIBUTION_STATE === 'SUCCEEDED' ? 1 : 0;
			selCanceledCtr += item.DISTRIBUTION_STATE.toUpperCase() === 'CANCELED' ? 1 : 0;
			selFailedCtr += item.DISTRIBUTION_STATE === 'FAILED' ? 1 : item.DISTRIBUTION_STATE === 'REJECTED' ? 1 : 0;
		});

		//Enable Cancel Button		
		if ((selScheduledCtr > 0 || selQueuedCtr > 0) && (selCompletedCtr === 0 && selFailedCtr === 0 && selCanceledCtr === 0)) {
			this.setState({
				cancelIcon: true,
			});
		}
		//Enable Delete Button
		if ((selCompletedCtr > 0 || selFailedCtr > 0 || selCanceledCtr > 0) && (selScheduledCtr === 0 && selQueuedCtr === 0 && selUpdatingCtr === 0 && selPendingCtr === 0)) {
			this.setState({
				deleteIcon: true,
			});
		}
		//Enable Retry Button
		if ((selFailedCtr > 0) && (selCompletedCtr === 0 && selScheduledCtr === 0 && selQueuedCtr === 0 && selUpdatingCtr === 0 && selPendingCtr === 0)) {
			this.setState({
				retryIcon: true,
			});
		}
	}

	checkBoxHandler = (e) => {
		let isChecked = e && e.target && e.target.checked;
		let selCheckboxName = e && e.target && e.target.value;
		this.setState({
			[e.target.value]: isChecked,
		}, () => {
			this.getSoftwareUpdateStatus(1);
		})
	}

	/**
	 * When navigate new page this event will return current page number
	 * @param {*} currentPage 
	 */
	onPageChange = (currentPage) => {
		this.setState({ loaderIconVisible: true }, () => {
			this.getSoftwareUpdateStatus(currentPage);
			window.scrollTo({
				top: 0,
				behavior: "smooth"
			});
		});
	}

	// Woopra Events
	trackEvent = (e, title = '') => {
		WoopraEvents(`${Constants.SOFTWARE}-${title || e.target.title}`);
	}

	// Woopra Events
	trackEvent_For_Cancel = (e) => {
		WoopraEvents(e);
	}

	render() {
		const { formatMessage } = this.props.intl;
		let { loaderIconVisible, sortColumn, mediaUpdateStatusInformation, distributionCounts,
			deleteIcon, cancelIcon, retryIcon, currentPage, pageSize,
			unitModelDetails, unitLocationDetails, unitSoftwareDetails, error_modal, success_modal } = this.state;
		let updatequeued = 0;
		let updating = 0;
		let updatecomplete = 0;
		let updatefailed = 0;
		let scheduled = 0;
		let pending = 0;
		let updatecanceled = 0;
		const { softwareUpdateStatusInfo } = this.props;
		let { Scheduled, Queued, Updating, Pending, Completed, Failed, Canceled } = this.state;
		let userProfileAuthFeature = authService.getFeature("/mediaLibraryList");

		let columns = [
			{ path: "", label: "", isNbSp: true },
			{ path: "", label: "#" },
			{ path: "BRAND", labelStringID: "KC0841" },
			{ path: "MODEL", labelStringID: "KC0647" },
			{ path: "MODEL_NO", labelStringID: "KC0050" },
			{ path: "UNITID", labelStringID: "KC0737" },
			{ path: "locationName", label: "" },
			{ path: "within_location", labelStringID: "KC0576" },
			{ path: "Start", labelStringID: "KC0577" },
			{ path: "End", labelStringID: "KC0578" },
			{ path: "Status", labelStringID: "KC0523" },
			{ path: "", label: "", isNbSp: true },
			{ path: "", label: "", isNbSp: true },
		];

		let mediaUpdateStatusList = mediaUpdateStatusInformation && mediaUpdateStatusInformation.length > 0 ? mediaUpdateStatusInformation : [];
		let selectedCount = 0;

		if (distributionCounts) {
			scheduled = distributionCounts.Scheduled ? distributionCounts.Scheduled : 0;
			updatequeued = distributionCounts.Queued ? distributionCounts.Queued : 0;
			updating = distributionCounts.IN_PROGRESS ? distributionCounts.IN_PROGRESS : 0;
			updatecomplete = distributionCounts.SUCCEEDED ? distributionCounts.SUCCEEDED : 0;
			updatefailed = distributionCounts.FAILED ? distributionCounts.FAILED : 0;
			updatecanceled = distributionCounts.CANCELED ? distributionCounts.CANCELED : 0;
		}

		mediaUpdateStatusList && mediaUpdateStatusList.forEach(item => {
			item.status = item.DISTRIBUTION_STATE === 'FAILED' ? "failed" : item.DISTRIBUTION_STATE === 'REJECTED' ? "failed" : item.DISTRIBUTION_STATE === 'SUCCEEDED' ? "completed" : item.DISTRIBUTION_STATE === 'Scheduled' ? "scheduled" : item.DISTRIBUTION_STATE === 'IN_PROGRESS' ? item.DISTRIBUTION_PROGRESS >= 50 ? "pending" : "updating" : "queued";
			selectedCount += item.checked ? 1 : 0;
		});

		mediaUpdateStatusList = _.orderBy(mediaUpdateStatusList, [sortColumn.path], [sortColumn.order]);
		let rowNumber = (currentPage * pageSize) - pageSize;
		//Model And Software info for moal popup
		let CtrlSoftFileName = unitModelDetails && unitModelDetails.stum_ver ? unitModelDetails.stum_ver : "";
		let CtrlSoftVersion = "";
		let CtrlSoftUDate = "";

		let MDPFileName = unitModelDetails && unitModelDetails.mpd_filename ? unitModelDetails.mpd_filename : "";
		let MDPVersion = "";
		let MDPUDate = "";

		let IPDFileName = unitModelDetails && unitModelDetails.ipd_filename ? unitModelDetails.ipd_filename : "";
		let IPDVersion = "";
		let IPDUDate = "";

		let MachinSetFileName = unitModelDetails && unitModelDetails.machine_setup ? unitModelDetails.machine_setup : "";
		let MachinSetVersion = "";
		let MachinSetUDate = "";

		let MenuFileName = unitModelDetails && unitModelDetails.FILE_NAME ? unitModelDetails.FILE_NAME : "";
		let MenuVersion = "";
		let MenuUDate = "";

		let CompilationFileName = unitModelDetails && unitModelDetails.compilation_ver ? unitModelDetails.compilation_ver : "";
		let CompilationVersion = "";
		let CompilationUDate = "";

		let IOTFileName = unitModelDetails && unitModelDetails.iot_version ? unitModelDetails.iot_version : "";
		let IOTVersion = "";
		let IOTUDate = "";

		let value = "";
		if (unitSoftwareDetails) {
			if (unitSoftwareDetails.constrolSoftware) {
				value = unitSoftwareDetails.constrolSoftware;
				CtrlSoftUDate = value.lastUpdatedAt ? momentHelper.GetDateTimeByFormat(value.lastUpdatedAt, 'LLL') : "";

			}

			if (unitSoftwareDetails.mdp) {
				value = unitSoftwareDetails.mdp;
				MDPUDate = value.lastUpdatedAt ? momentHelper.GetDateTimeByFormat(value.lastUpdatedAt, 'LLL') : "";
			}

			if (unitSoftwareDetails.ipd) {
				value = unitSoftwareDetails.ipd;
				IPDUDate = value.lastUpdatedAt ? momentHelper.GetDateTimeByFormat(value.lastUpdatedAt, 'LLL') : "";
			}


			if (unitSoftwareDetails.machine) {
				value = unitSoftwareDetails.machine;
				MachinSetUDate = value.lastUpdatedAt ? momentHelper.GetDateTimeByFormat(value.lastUpdatedAt, 'LLL') : "";
			}

			if (unitSoftwareDetails.menu) {
				value = unitSoftwareDetails.menu;
				MachinSetUDate = value.lastUpdatedAt ? momentHelper.GetDateTimeByFormat(value.lastUpdatedAt, 'LLL') : "";
			}

			if (unitSoftwareDetails.compilation) {
				value = unitSoftwareDetails.compilation;
				CompilationUDate = value.lastUpdatedAt ? momentHelper.GetDateTimeByFormat(value.lastUpdatedAt, 'LLL') : "";
			}
		}
		//Model And Software info for moal popup End
		const Deleteimage = require("../../../images/icons/DeleteIcon.png");
		const zipimage = require("../../../images/icons/ZipIcon.png");
		return (
			<React.Fragment>
				<div id="adminWrapper">
					{/* <!-- Loading Progress --> */}
					<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
						<div className="loadingProgressIn"></div>
					</div>
					{/* <!-- //Loading Progress -->*/}
					<div id="page-content-wrapper">

						<div className="softwareUpdateWrapper">
							<div className="tableHNav">
								<ul>
									<li>
										<Link to="/mediaLibraryList" title={formatMessage({ id: 'KC1924' })} onClick={(e) => this.trackEvent(e, 'Media Library')}><FormattedMessage id="KC1924" /></Link>
										<Link to="/mediaUploadHistory" title={formatMessage({ id: 'KC1757' })} onClick={(e) => this.trackEvent(e, 'Media Upload History')}><FormattedMessage id="KC0713" /></Link>
										<Link to="#" title={formatMessage({ id: 'KC0659' })} className="active" onClick={(e) => this.trackEvent(e, 'Update Status')}><FormattedMessage id="KC0523" /></Link>
									</li>
									<li>
										{/* <button onClick={() => this.onSmartGroupClick()} type="button" className="btn gridSG">&nbsp;</button> */}
										<button type="button" className="btn listView active">&nbsp;</button>
										<button type="button" className={retryIcon && userProfileAuthFeature.is_editable === 1 ? "btn referesh" : "btn referesh disabled"} data-toggle="modal" data-target="#RetryConfirmationModal">&nbsp;</button>
										<button type="button" className={cancelIcon && userProfileAuthFeature.is_editable === 1 ? "btn cancelTransfer" : "btn cancelTransfer disabled"} data-toggle="modal" data-target="#DeleteConfirmationModal">&nbsp;</button>
										<button type="button" className={deleteIcon && userProfileAuthFeature.is_editable === 1 ? "btn delete" : "btn delete disabled"} data-toggle="modal" data-target="#confirmationDeleteModal">&nbsp;</button>
									</li>
								</ul>
							</div>

							<div className="selectedUnitHeader">
								<ul>
									<li>
										<div className="checkBoxListGrp">
											<div className="customCheckbox crticalCBox">
												<input onClick={(e) => this.checkBoxHandler(e)} id="Scheduled" type="checkbox" value="Scheduled" checked={Scheduled} />
												<label htmlFor="Scheduled" title={formatMessage({ id: 'KC1727' })}><FormattedMessage id="KC0573" /> <span> {scheduled}</span></label>
											</div>
											<div className="customCheckbox nonCrticalCBox">
												<input onClick={(e) => this.checkBoxHandler(e)} id="Queued" type="checkbox" value="Queued" checked={Queued} />
												<label htmlFor="Queued" title={formatMessage({ id: 'KC1728' })}><FormattedMessage id="KC0691" /> <span>{updatequeued}</span></label>
											</div>
											<div className="customCheckbox">
												<input onClick={(e) => this.checkBoxHandler(e)} id="Updating" type="checkbox" value="Updating" checked={Updating} />
												<label htmlFor="Updating" title={formatMessage({ id: 'KC1729' })}><FormattedMessage id="KC0692" /> <span>{updating}</span></label>
											</div>
											{/* <div class="customCheckbox">
													<input onClick={(e) => this.checkBoxHandler(e)} id="Pending" type="checkbox" value="Pending" checked = {Pending}/>
													<label for="Pending">Operator Install<span>{pending}</span></label>
												</div> */}
											<div className="customCheckbox">
												<input onClick={(e) => this.checkBoxHandler(e)} id="Failed" type="checkbox" value="Failed" checked={Failed} />
												<label htmlFor="Failed" title={formatMessage({ id: 'KC1730' })}><FormattedMessage id="KC1119" /> <span>{updatefailed}</span></label>
											</div>
											<div className="customCheckbox">
												<input onClick={(e) => this.checkBoxHandler(e)} id="Completed" type="checkbox" value="Completed" checked={Completed} />
												<label htmlFor="Completed" title={formatMessage({ id: 'KC1731' })}><FormattedMessage id="KC1118" /> <span>{updatecomplete}</span></label>
											</div>
											<div className="customCheckbox">
												<input onClick={(e) => this.checkBoxHandler(e)} id="Canceled" type="checkbox" value="Canceled" checked={Canceled} />
												<label htmlFor="Canceled" title={formatMessage({ id: 'KC1732' })}><FormattedMessage id="KC0694" /> <span>{updatecanceled}</span></label>
											</div>
										</div>

									</li>
									<li>
										<div className="dateRangeForm">
											<span><FormattedMessage id="KC0695" />:</span>
											<DatePicker locale={datePickerLocale} autoComplete="off" id="startdate" showMonthDropdown showYearDropdown dropdownMode="select" onKeyDown={(e) => this.ResetDatepicker(e)} selected={this.state.startDate} onChange={this.handleChangestart} minDate={this.state.minDate} maxDate={this.state.maxdate} />
											<span><FormattedMessage id="KC0696" />:</span>
											<DatePicker locale={datePickerLocale} popperModifiers={{ preventOverflow: { enabled: true, }, }} autoComplete="off" id="enddate" showMonthDropdown showYearDropdown dropdownMode="select" onKeyDown={(e) => this.ResetDatepicker(e)} selected={this.state.endDate} onChange={this.handleChangeend} minDate={this.state.minDate} maxDate={this.state.maxdate} />
										</div>
									</li>
								</ul>
							</div>
							<div className="updateStatusTableOuter">
								<div className="statusTable">
									<TableHeader
										userMgmtListScreen={true}
										sortColumn={sortColumn}
										onSort={this.onSort}
										columns={columns} />
								</div>
								<div className='statusTableSrlBar'>
									<div className="panel panel-default" id="availableUpdatesAccordion" role="tablist" aria-multiselectable="true">
										{/* <!-- Header 01 --> */}
										{/* mediaUpdateStatusList */}
										{mediaUpdateStatusList.map((item, i) => {
											return (
												<React.Fragment key={i}>
													<div className="statusTable">
														<ul className="tBody">
															<li><a role="button" data-toggle="collapse" draggable="false" data-parent="#availableUpdatesAccordion" data-target={`#availableUpdatesAcc` + item.ID} aria-expanded="false" aria-controls="availableUpdatesAcc1" className="btn collapseExpand collapsed">&nbsp;</a></li>
															<li>{rowNumber + i + 1}</li>
															<li>{item.UNITID}</li>
															<li>{item.assest_number}</li>
															<li>{item.locationName}</li>
															<li>{item.mediaList[0].FILE_NAME ? item.mediaList[0].FILE_NAME : ""} </li>
															<li></li>
															<li>{item.DISTRIBUTED_BY}</li>
															<li>{item.START_TIME !== "NULL" && item.START_TIME !== null ? momentHelper.GetDateTimeByFormat(item.START_TIME, 'LLL') : ""}</li>
															<li>{item.END_TIME !== "NULL" && item.END_TIME !== null ? momentHelper.GetDateTimeByFormat(item.END_TIME, 'LLL') : ""}</li>
															<li>
																<img src={
																	item.DISTRIBUTION_STATE === 'FAILED' ? require('../../../images/icons/ErrorD.svg').default
																		: item.DISTRIBUTION_STATE === 'REJECTED' ? require('../../../images/icons/ErrorD.svg').default
																			: item.DISTRIBUTION_STATE === 'Scheduled' ? require('../../../images/icons/clockS.svg').default
																				: item.DISTRIBUTION_STATE === 'Operator-Install' || item.TRANSFER_TYPE === "operator-install" ? require('../../../images/icons/usersD.svg').default
																					: item.DISTRIBUTION_STATE === 'SUCCEEDED' ? require('../../../images/icons/confirmGreen.svg').default
																						: item.DISTRIBUTION_STATE.toUpperCase() === 'CANCELED' ? require('../../../images/icons/cancelIcon.png')
																							: require('../../../images/icons/UpdateD.svg').default} />
															</li>
															<li>
																<div className="progress">
																	<div className={
																		item.DISTRIBUTION_STATE.toUpperCase() === 'CANCELED' ? "progress-bar cancelStatus"
																			: item.DISTRIBUTION_STATE === 'FAILED' ? "progress-bar warningStatus"
																				: item.DISTRIBUTION_STATE === 'REJECTED' ? "progress-bar warningStatus"
																					: item.DISTRIBUTION_STATE === 'Scheduled' || item.DISTRIBUTION_STATE === 'Queued' ? "progress-bar updatingSW"
																						: item.DISTRIBUTION_STATE === 'SUCCEEDED' ? "progress-bar completedStatus" : "progress-bar updatingSW"}
																		style={{ width: item.DISTRIBUTION_STATE.toUpperCase() === 'CANCELED' ? "100%" : item.DISTRIBUTION_PROGRESS + '%' }}></div>
																</div>
															</li>
															<li>
																{!item.isAuthorized ? <Tooltip content={formatMessage({ id: 'KC1733' })} direction="left" className="targetdisabled">
																	<div className={"customCheckbox customCheckboxWOlabel disabled"}>
																		<input id={i} name={i} type="checkbox"
																			checked={item.checked}
																			disabled
																			onChange={(e) => this.onSelectRole(e, item)} />
																		<label htmlFor={i}></label>
																	</div>
																</Tooltip> : <div className={"customCheckbox customCheckboxWOlabel"}>
																	<input id={i} name={i} type="checkbox"
																		checked={item.checked}
																		onChange={(e) => this.onSelectRole(e, item)} />
																	<label htmlFor={i}></label>
																</div>}

															</li>
														</ul>
													</div>
													{/* <!-- Header 01 Content --> */}
													<div id={`availableUpdatesAcc` + item.ID} className="panel-collapse collapse" role="tabpanel" aria-labelledby={`availableUpdatesAccH` + item.ID}>
														<div className="panel-body updateStatusExpTable statusExpTableW">
															<div className="statusExpTable">
																{item.mediaList && item.mediaList.map((items, i) =>
																	<React.Fragment>
																		<ul key={i}>
																			<li>
																				<h5><FormattedMessage id="KC1554" /></h5>
																				<div className="packageDetails">
																					<ul>
																						<li>
																							<span className="mediaFileSpan"><img src={items.STATUS === "DELETED" ? Deleteimage : (items.FILE_FORMAT === '.zip' ? zipimage : items.thumbUrl)} alt="" /></span>
																						</li>
																						<li>
																							<h6><span><FormattedMessage id="KC1504" />: </span> {item.FILE_NAME ? item.FILE_NAME : ""}</h6>
																							{/* <h6><span>Version: </span> {item.VERSION?item.VERSION:""} </h6> */}
																							<h6><span><FormattedMessage id="KC0102" />: </span> {item.TYPE ? item.TYPE : ""}</h6>
																							<h6><span><FormattedMessage id="KC1144" />: </span> <FormattedMessage id="KC1734" values={{ size: item.FILE_SIZE ? item.FILE_SIZE : 0 }} /></h6>
																						</li>
																					</ul>
																				</div>
																			</li>
																			<li>
																				<h5><FormattedMessage id="KC1781" /></h5>
																				<div className="updateHistoryT">
																					{item.lastStatus && item.lastStatus.map((itemStatus, i) => (<Fragment key={i}>
																						<ul>
																							<li>{i + 1}</li>
																							<li><img src={itemStatus.DISTRIBUTION_STATE === 'FAILED' ? require('../../../images/icons/ErrorD.svg').default : itemStatus.DISTRIBUTION_STATE === 'REJECTED' ? require('../../../images/icons/ErrorD.svg').default : itemStatus.DISTRIBUTION_STATE === 'Scheduled' ? require('../../../images/icons/clockS.svg').default : itemStatus.DISTRIBUTION_STATE === 'SUCCEEDED' ? require('../../../images/icons/confirmGreen.svg').default : require('../../../images/icons/UpdateD.svg').default} /></li>
																							<li><FormattedMessage id="KC1555" /></li>
																							<li>{itemStatus.END_TIME ? momentHelper.GetDateTimeByFormat(itemStatus.END_TIME, 'LLL') : ""}</li>
																						</ul>
																					</Fragment>))}
																				</div>
																			</li>
																			<li>
																				<button type="button" onClick={(e) => this.getUnitPopUpDetails(item)} className="btn btn-default unitDetailsBtn" data-toggle="modal" data-target="#modelInfoModal"><FormattedMessage id="KC1505" /></button>
																			</li>
																		</ul>
																	</React.Fragment>
																)}
															</div>
														</div>
													</div>

													{/* Info Modal */}
													<div className="modal fade infoModal" data-keyboard="true" data-backdrop="static" id="modelInfoModal" tabIndex="-1" role="dialog" aria-labelledby="modelInfoModalLabel">
														<div className="modal-dialog modal-md infoModal sgModal">
															<div className="modal-content">
																<div className="modal-header modal-header-danger">
																	<FormattedMessage id="KC1782" /> <button type="button" className="close" data-dismiss="modal" aria-label="Close">&nbsp;</button>
																</div>
																<div className="modal-body">
																	<div className="modelInfoModal">
																		<div className="modelInfoModalL">
																			<div className="modelInfoModalT"><FormattedMessage id="KC0673" /></div>
																			<div className="modelInfoDetails">
																				<ul>
																					{/* <li class="modelImgView"><img src="images/unit/Crem-PSL50ES.svg" /></li>  */}
																					<li className="modelImgView"><img alt={unitLocationDetails.Brand_Alt} src={unitLocationDetails.Brand_Logo} /></li>
																					<li>
																						<h6><span><FormattedMessage id="KC0841" />: </span> {unitModelDetails ? unitModelDetails.equip_id : ""}</h6>
																						<h6><span><FormattedMessage id="KC0845" />: </span> {unitModelDetails ? unitModelDetails.assetNumber : ""}</h6>
																						<h6><span><FormattedMessage id="KC1506" />: </span>{unitModelDetails ? unitModelDetails.network_type : ""} </h6>
																						<h6><span><FormattedMessage id="KC1507" />: </span>{unitModelDetails ? unitModelDetails.signal_level : ""}</h6>
																					</li>
																				</ul>
																			</div>
																		</div>
																		<div className="modelInfoModalR">
																			<div className="modelInfoModalT"><FormattedMessage id="KC0050" /></div>
																			<div className="modelInfoDetails">
																				<ul>
																					<li>
																						<h6><span><FormattedMessage id="KC0675" />: </span> {unitLocationDetails ? unitLocationDetails.location : ""}</h6>
																						<h6><span><FormattedMessage id="KC0099" />: </span> {unitLocationDetails ? unitLocationDetails.street : ""}</h6>
																						<h6><span><FormattedMessage id="KC0098" />: </span> {unitLocationDetails ? unitLocationDetails.city : ""}</h6>
																						<h6><span><FormattedMessage id="KC1508" />: </span>{unitLocationDetails ? unitLocationDetails.zipcode : ""} </h6>
																					</li>
																				</ul>
																			</div>
																		</div>
																		<div className="modelInfoModalT"><FormattedMessage id="KC0546" /></div>
																		<div className="packageStatusTable">
																			<ul className="tHead">
																				<li>&nbsp;</li>
																				<li><FormattedMessage id="KC0737" /></li>
																				<li><FormattedMessage id="KC0834" /></li>
																				<li><FormattedMessage id="KC1557" /></li>
																			</ul>
																			<ul className="tBody">
																				<li><FormattedMessage id="KC1558" /></li>
																				<li>{CtrlSoftFileName}</li>
																				<li>{CtrlSoftVersion}</li>
																				<li>{CtrlSoftUDate}</li>
																			</ul>
																			<ul className="tBody">
																				<li><FormattedMessage id="KC0233" /></li>
																				<li>{MDPFileName}</li>
																				<li>{MDPVersion}</li>
																				<li>{MDPUDate}</li>
																			</ul>
																			<ul className="tBody">
																				<li><FormattedMessage id="KC0234" /></li>
																				<li>{IPDFileName}</li>
																				<li>{IPDVersion}</li>
																				<li>{IPDUDate}</li>
																			</ul>
																			<ul className="tBody">
																				<li><FormattedMessage id="KC0235" /></li>
																				<li>{MachinSetFileName}</li>
																				<li>{MachinSetVersion}</li>
																				<li>{MachinSetUDate}</li>
																			</ul>
																			<ul className="tBody">
																				<li><FormattedMessage id="KC0152" /></li>
																				<li>{MenuFileName}</li>
																				<li>{MenuVersion}</li>
																				<li>{MenuUDate}</li>
																			</ul>
																			<ul className="tBody">
																				<li><FormattedMessage id="KC0736" /></li>
																				<li>{CompilationFileName}</li>
																				<li>{CompilationVersion}</li>
																				<li>{CompilationUDate}</li>
																			</ul>
																			<ul className="tBody">
																				<li><FormattedMessage id="KC1559" /></li>
																				<li>{IOTFileName}</li>
																				<li>{IOTVersion}</li>
																				<li>{IOTUDate}</li>
																			</ul>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													{/* Info Moda */}
												</React.Fragment>
											)
										})
										}
									</div>
									<NoRecordsFound loaderIconVisible={loaderIconVisible} length={mediaUpdateStatusList !== undefined ? mediaUpdateStatusList.length : 0} classname={'norecordsfoundbluetext'} />
								</div>
								{/* <Paginator
										pageNumber={this.state.currentPage}
										pageCount={this.state.totalPages}
										onNextPage={(p) => this.onPageChange(p)}
										onPreviousPage={(p) => this.onPageChange(p)}
										applyClass="tablePagination"
									/> */}
								{this.state.totalPages && this.state.totalPages > 1 ? <GoToPagination
									totalPageNumber={this.state.totalPages}
									pageNumber={this.state.currentPage}
									navigateTo={(p) => this.onPageChange(p)} /> : null}
							</div>

						</div>
						{/* Modal for cancel */}
						<div className="modal fade SignOutModal" data-keyboard="true" data-backdrop="static" id="DeleteConfirmationModal" tabIndex="-1" role="dialog" aria-labelledby="SignOutModalLabel">
							<div className="modal-dialog modal-md loginModal">
								<div className="modal-content" >
									<div className="modal-body">
										<div className="singOutConfirmation">
											<FormattedMessage id="KC1509" />
										</div>
									</div>
									<div className="modal-footer textAlignCenter">
										<button className="btn btn-secondary signOutNoBtn" data-dismiss="modal" type="button" onClick={() => this.trackEvent_For_Cancel("Canceled Clear Distributions")}><FormattedMessage id="KC0580" /></button>
										<button className="btn btn-secondary signOutYesBtn" onClick={() => this.onDelete('clear')} data-dismiss="modal" type="button"><FormattedMessage id="KC0611" /></button>
									</div>
								</div>
							</div>
						</div>

						{/* Modal for delete */}
						<div className="modal fade SignOutModal" data-keyboard="true" data-backdrop="static" id="confirmationDeleteModal" tabIndex="-1" role="dialog" aria-labelledby="SignOutModalLabel">
							<div className="modal-dialog modal-md loginModal">
								<div className="modal-content" >
									<div className="modal-body">
										<div className="singOutConfirmation">
											<FormattedMessage id="KC0579" />
										</div>
									</div>
									<div className="modal-footer textAlignCenter">
										<button className="btn btn-secondary signOutNoBtn" data-dismiss="modal" type="button" onClick={() => this.trackEvent_For_Cancel("Canceled Delete Distributions")}><FormattedMessage id="KC0580" /></button>
										<button className="btn btn-secondary signOutYesBtn" onClick={() => this.onDelete('delete')} data-dismiss="modal" type="button"><FormattedMessage id="KC0611" /></button>
									</div>
								</div>
							</div>
						</div>

						{/* Modal for Retry */}
						<div className="modal fade SignOutModal" data-keyboard="true" data-backdrop="static" id="RetryConfirmationModal" tabIndex="-1" role="dialog" aria-labelledby="SignOutModalLabel">
							<div className="modal-dialog modal-md loginModal">
								<div className="modal-content" >
									<div className="modal-body">
										<div className="singOutConfirmation">
											<FormattedMessage id="KC0757" />
										</div>
									</div>
									<div className="modal-footer textAlignCenter">
										<button className="btn btn-secondary signOutNoBtn" data-dismiss="modal" type="button" onClick={() => this.trackEvent_For_Cancel("Canceled Retry Distributions")}><FormattedMessage id="KC0580" /></button>
										<button className="btn btn-secondary signOutYesBtn" onClick={() => this.onRetry('delete')} data-dismiss="modal" type="button"><FormattedMessage id="KC0611" /></button>
									</div>
								</div>
							</div>
						</div>
						<ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
					</div>
					<UserManagementHeader headerName="Media Management" headerNameStringID="KC0632" activeClass="mediaNav" />
					<SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
					{/* Toast Notification */}
					{this.state.showToast && (
						<div className="alert toasMsgAlert alert-dismissible fade show" role="alert">
							<span className="toasMsg">{formatMessage({ id: "KC2928" }).replace("{{number}}",this.props.location.state.totalUnitsCount) }</span>
							<button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={this.closeToast}>&nbsp;</button>
						</div>
					)}
				</div>
			</React.Fragment>

		)
	}
};

function mapStateToProps(state) {
	return {
		//softwareUpdateStatusInfo: state.mediaUpdateStatusInformation.mediaUpdateStatusInformation,
		searchData: state.advanceSearch.searchData
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		getSoftwareUpdateStatus,
		searchVisible, searchText, clearSearchText
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MediaStatus));